<template>
  <v-card color="#193154" class='white--text pa-4 mt-3 text-justify mx-auto rounded-0 mb-10' width="auto">
      <v-row>
        <div class="text pa-2">
        Depuis l’année 2020-2021, nous sommes fiers de participer à des projets spatiaux technique d’envergure en étroite collaboration avec le CNES, dont le projet PERSEUS effectué dans le cadre du PIE de 2e année du cycle ingénieur ; ainsi que le concours C’Space, permettant aux 1A de s’investir dans des projets techniques.
        </div>
      </v-row>

      <v-row class="mt-12">
          <v-col cols="5">
              <v-img
              src="@/assets/logo-antares.png"
              max-width="250"
              class="mx-auto antares"
              ></v-img>

              <div class="text-center event-button mt-5">
                <v-btn x-large outlined color=#ffffff class="rounded-0 ma-3" to="/projets_en_cours/antares">
                      En savoir plus 
                <v-icon
                light
                right
                >
                mdi-arrow-right
                </v-icon>
                </v-btn>
              </div>
          </v-col>
          <v-col cols="2">
              <v-img
              src="@/assets/cross.png"
              max-width="100"
              class="mx-auto cross"
              ></v-img>

          </v-col>
          <v-col cols="5">
              <v-img
              src="@/assets/logo-perseus.png"
              max-width="250"
              class="mx-auto perseus"
              ></v-img>
              <div class="text-center event-button mt-5">
                <v-btn x-large outlined color=#ffffff class="rounded-0 ma-3" to="/projets_en_cours/perseus">
                      En savoir plus 
                <v-icon
                light
                right
                >
                mdi-arrow-right
                </v-icon>
                </v-btn>
              </div>
          </v-col>
      </v-row>

  </v-card>
</template>

<script>
export default {

}
</script>

<style>
.text {
  font-size: 20px;
}
.subtitle-left {
  font-size: 30px;
  padding-left: 40%;
  margin-top: 15px;
  font-weight: 200;
  font-style: italic;
  letter-spacing: 4px;
}

.subtitle-right {
  font-size: 30px;
  padding-right: 20%;
  margin-top: 15px;
  font-weight: 200;
  font-style: italic;
  letter-spacing: 4px;
}
.cross {
  margin-top: 70px;
}
.antares{
  margin-left: 50%;
}
.perseus {
  margin-right: 50%;
}

</style>