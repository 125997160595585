<template>
  <v-card color="#193154" class='white--text pa-4 mt-3 text-justify mx-auto rounded-0 mb-10' width="auto">
    <v-row>
      <v-col cols="7">
        <div class="text">
        ENST’Air est l’association étudiante d’aéronautique et de spatial de l’ENSTA Paris. Créée en septembre 2014 et devenue Association loi 1901 depuis printemps 2016, son objectif est de stimuler la passion pour l’aéronautique et le spatial pour les étudiants de l’ENSTA Paris par le biais d’évènements scientifiques. Nos projets techniques fédèrent actuellement une quarantaine d’étudiants motivés de l’Ecole.
        </div>
      </v-col>
      
      <v-col cols="5" class='pr-3'>
          <v-img
          src="@/assets/bureau-2020.jpg"
          ></v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {

}
</script>

<style>
.text {
  font-size: 20px;
}

</style>