<template>
  <v-row class="mt-2">
      <v-col>
          <v-img
          src="@/assets/logo-ensta.png"
          max-width="200"
          class="mx-auto"
          ></v-img>
      </v-col>
      <v-col>
          <v-img
          src="@/assets/logo-cnes.png"
          max-width="220"
          class="mx-auto"
          ></v-img>
      </v-col>
  </v-row>
</template>

<script>
export default {

}
</script>

<style>

</style>