<template>
  <v-container>
    <div class="first-image">
      <img src="../assets/logo-enst-air.png" app width="700" />
    </div>
    
    <div class="slogan mb-10">
      <span>L'association aéronautique et aérospatiale de l'ENSTA Paris</span>
    </div>

    <video-background 
        :src="require('../assets/accueil/ENST_Air_Acc.mp4')"
        :poster="require('../assets/accueil/0237.png')"
        style="max-height: 500px; height: 100vh;"
        :muted="false"
        :autoplay="useAutoplay"
        justify-center
        ref=videobackground
    >
    </video-background>
    <v-row justify="center" class="mt-3">
    <v-btn
      fab
      dark
      small 
      color=#193154 
      large
      class="mx-2 rounded-0" @click="PlayPauseMethod()">
          
    <v-icon
    light
    v-if="notLaunched"
    
    >
    mdi-play
    </v-icon>
    <v-icon
    light
    v-else
    >
    mdi-pause
    </v-icon>
    </v-btn>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="text-left activite">Nos membres</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <MemberCard />
    <div>
      <v-row>
        <v-col>
          <h2 class="text-right activite">Nos activités</h2>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <span class="text-justify text-activite mt-5"
          >Nous proposons des activités variées. De la plus ludique à la plus
          ambitieuse, nous tâchons de satisfaire les envies du plus grand nombre
          tout en réunissant les passionnés d’aérospatial dans un cadre
          convivial et stimulant.
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="activity in activities"
        :key="activity.id"
      >
        <ActivityCard :activity="activity" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2 class="text-left activite">Des projets ambitieux</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <AmbitiousProject />


    <v-row>
      <v-col>
        <h2 class="text-right activite">Nos collaborateurs</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <Partners />
  </v-container>
</template>

<script>
import ActivityCard from "../components/ActivityCard.vue";
import MemberCard from "@/components/MemberCard.vue";
import AmbitiousProject from "@/components/AmbitiousProject.vue";
import Partners from "@/components/Partners.vue";
import VideoBackground from 'vue-responsive-video-background-player'

export default {
  name: "Accueil",
  components: {
    ActivityCard,
    MemberCard,
    AmbitiousProject,
    Partners,
    VideoBackground
  },
  data() {
    return {
      useAutoplay: false,
      notLaunched: true,
      activities: [
        {
          name: "Baptême de l'air",
          id: "001",
          description:
            "Plusieurs fois par an, nous organisons avec notre aéroclub partenaire GAMA des séances de baptêmes de l’air à prix très avantageux pour les étudiants de l’école.",
          image: "bapteme.jpg",
        },
        {
          name: "Mécanique du vol",
          id: "002",
          description:
            "Deux alumni, Camille Fournis (doctorat en mécanique des fluides à l’ONERA) et Paul Dinthillac (fondateur de l’association et apprenti pilote de ligne) organisent chaque année six sessions d’environ une heure de cours de mécanique du vol en novembre-décembre. Ils y abordent notamment des aspects pratiques de pilotage ainsi que des fondamentaux plus théoriques. ",
          image: "mecavol.png",
        },
        {
          name: "Visites",
          id: "003",
          description:
            "Plusieurs fois par an, nous participons à des salons aéronautiques (Bourget, Berlin, Ferté Allais…) et organisons des visites des locaux de l’ONERA pour susciter ou confirmer l’intérêt des ENSTA pour le secteur aérospatial. Nous participons aussi occasionnellement à des colloques et cercles de réflexion sur les enjeux actuels de ce secteur, colloques organisés par l’ONERA. ",
          image: "bourget.jpg",
        },
      ],
    };
  },
  methods: {
    onIntersect(entry) {
      this.isIntersecting = entry[0].isIntersecting;
    },
    PlayPauseMethod() {
      if (this.notLaunched){
        this.$refs.videobackground.player.play();
        this.notLaunched = !this.notLaunched;
      }else{
        this.$refs.videobackground.player.pause();
        this.notLaunched = !this.notLaunched;
      }
      
    }
  },
  computed : {
    
  }
  
};
</script>

<style>
.first-image {
  margin-left: 210px;
}
.slogan {
  text-align: center;
  font-size: 25px;
  font-style: italic;
  font-weight: lighter;
  color: #193154;
}
.activite {
  font-size: 35px;
  font-weight: bolder;
  color: #193154;
}
.border {
  border-radius: 10px;
}
.text-activite {
  font-size: 20px;
  margin-top: 20px;
}
</style>