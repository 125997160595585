<template>
  <v-card class="event-card text-color" min-height="730">
    <v-img :src="require('../assets/' + activity.image)"
    aspect-ratio="1"></v-img>
    <div class="title">
      <v-card-title>{{ activity.name }}</v-card-title>
    </div>

    <div class="text-justify mb-auto">
      {{ activity.description }}
    </div>

    <div class="text-center event-button">
      <v-btn rounded large depressed color="#193154" class="white--text">
        Voir la page
        <v-icon light right> mdi-arrow-right </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ActivityCard",
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
   imageSrc() {
      return this.activity.image;
   }
  }
};
</script>

<style scoped>
.event-card {
  padding: 20px;
  margin: 20px;
  margin-bottom: 18px;
}

.event-button {
  margin-top: 15px;
}

.title {
  color: #193154;
}
</style>